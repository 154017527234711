import React from "react"
import {useStaticQuery, graphql} from 'gatsby'

import './cover.scss'
import Img from "gatsby-image"

const Cover = () => {
    const data = useStaticQuery(graphql`
        query {
            file: file(relativePath: { eq: "cover.jpg" }) {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                }
              }
            },
            file2: file(relativePath: { eq: "vizualizace.png" }) {
              childImageSharp {
                fluid(quality: 100, maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
        }
      `
    )

    return (
        <section className="cover" id="home">
            <Img fluid={data.file.childImageSharp.fluid}/>
            <div className="container flex justify-content-right align-items-center">
                <div className="text">
                    <h1>Jezevčí Mast</h1>
                    <h2>Prsní balzám pro lepší dýchání<br /> a uvolnění hlenů</h2>
                    <p>Unikátní bylinný přípravek s obsahem 20 druhů bylin<br /> v BIO kokosovém másle</p>
                </div>
            </div>
            <Img fluid={data.file2.childImageSharp.fluid}/>
        </section>
    )
}

export default Cover
