import React from "react"
import {useStaticQuery, graphql} from 'gatsby'

import './about.scss'
import Img from "gatsby-image"

import Icon1 from '../../svg/icon_1.svg'
import Icon2 from '../../svg/icon_2.svg'
import Icon3 from '../../svg/icon_3.svg'

const About = () => {
    const data = useStaticQuery(graphql`
        query {
            file: file(relativePath: { eq: "about-1.jpg" }) {
              childImageSharp {
                fluid(quality: 100, maxWidth: 960) {
                    ...GatsbyImageSharpFluid_withWebp
                }
              }
            },
            file2: file(relativePath: { eq: "about-2.jpg" }) {
              childImageSharp {
                fixed(quality: 100, width: 250) {
                    ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
            file3: file(relativePath: { eq: "about-3.png" }) {
              childImageSharp {
                fixed(quality: 100, width: 300) {
                    ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
        }
      `
    )

    return (
        <section className="about text-align-center" id="about">
            <Img fluid={data.file.childImageSharp.fluid} />
            <Img fluid={data.file2.childImageSharp.fixed} />
            <Img fluid={data.file3.childImageSharp.fixed} />

            <div className="container">
                <div className="text">
                    <p className="label">Obsahuje 20 bylin</p>
                    <h2>Unikátní bylinný<br /> prsní balzám</h2>

                    <p>
                        Unikátní bylinný přípravek nahrazuje osvědčený účinek nedostupného jezevčího sádla, které se
                        v tradičním lidovém léčitelství užívalo jako prsní balzám pro lepší průběh odkašlávání. Přípravek obsahuje harmonicky vyváženou směs 20 druhů bylinných výtažků v BIO kokosovém másle. Jezevčí mast
                        je vhodná k potírání a masáži v oblasti hrudníku, krku, zátylku, zad, případně čela a spánků.
                    </p>

                    <p>
                        <strong>Po masáži se rychle uvolňují aktivní látky, které:</strong>
                    </p>

                    <div className="icons flex justify-content-center">
                        <div className="item">
                            <Icon1 height="75" />
                            <h3>
                                Příznivě působí<br /> při dýchacích potížích
                            </h3>
                        </div>


                        <div className="item">
                            <Icon2 height="75" />

                            <h3>
                                Uvolňuje hleny<br /> a zlepšuje odkašlávání
                            </h3>
                        </div>

                        <div className="item">
                            <Icon3 height="75" />

                            <h3>
                                Pomáhají uvolňovat kašlem<br /> namáhané svalstvo
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About
