import React from "react"
import {useStaticQuery, graphql} from 'gatsby'

import './effects.scss'
import Img from "gatsby-image"

const Effects = () => {
    const data = useStaticQuery(graphql`
        query {
            file: file(relativePath: { eq: "using.jpg" }) {
              childImageSharp {
                fluid(quality: 100, maxWidth: 960) {
                    ...GatsbyImageSharpFluid_withWebp
                }
              }
            },
            file2: file(relativePath: { eq: "about-6.jpg" }) {
              childImageSharp {
                fixed(quality: 100, width: 250) {
                    ...GatsbyImageSharpFixed_withWebp
                }
              }
            },
            file3: file(relativePath: { eq: "about-5.jpg" }) {
              childImageSharp {
                fixed(quality: 100, width: 500) {
                    ...GatsbyImageSharpFixed_withWebp
                }
              }
            },
            file4: file(relativePath: { eq: "coconut.jpg" }) {
              childImageSharp {
                fixed(quality: 100, width: 200) {
                    ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
        }
      `
    )

    return (
        <section className="effects" id="effects">
            <Img fluid={data.file2.childImageSharp.fixed} />
            <Img fluid={data.file3.childImageSharp.fixed} />

            <div className="container">
                <div className="text">
                    <div className="using flex align-items-center justify-content-right">
                        <div className="text">
                            <h3>Způsob použití</h3>
                            <p>
                                <strong>V oblasti hrudníku naneste</strong> dostatečné množství přípravku
                                a důkladně vmasírujte, <strong>nebo při silnějších obtížích použijte jako zábal. Pro zvýšení účinnosti je vhodné  balzám aplikovat i v okolí nosních dírek, na krk, čelo, spánky i zátylek. </strong>
                                Pro dosažení maximálního účinku je vhodné proceduru zopakovat několikrát denně.
                            </p>
                            <h3>O účincích je známo, že:</h3>
                            <ul>
                                <li><strong>příznivě působí při dýchacích obtížích</strong></li>
                                <li><strong>zlepšují odkašlávání a uvolňují hleny</strong></li>
                                <li><strong>navozují pocit lepšího dýchání</strong></li>
                                <li><strong>pomáhájí uvolňovat kašlem namáhané svalstvo</strong></li>
                            </ul>
                        </div>
                        <div className="image">
                            <Img fluid={data.file.childImageSharp.fluid} />
                        </div>
                    </div>
                </div>
                <div className="text">
                    <Img fluid={data.file4.childImageSharp.fixed} />

                    <p className="label text-align-center">Účinné látky</p>
                    <h2 className="text-align-center">Harmonicky vyvážená<br /> směs bylin</h2>

                    <div className="cols flex justify-content-center">
                        <div className="col">
                            <ul>
                                <li><span>Kokosové máslo</span></li>
                                <li><span>Shea butter</span></li>
                                <li><span>Mandlový olej</span></li>
                                <li><span>Slunečnicový olej</span></li>
                                <li><span>Makadamiový olej</span></li>
                            </ul>
                        </div>

                        <div className="col">
                            <ul>
                                <li><span>Eukalyptová silice</span></li>
                                <li><span>Kosodřevinová silice</span></li>
                                <li><span>Jalovcová silice</span></li>
                                <li><span>Mátová silice</span></li>
                                <li><span>Santalová silice</span></li>
                            </ul>
                        </div>

                        <div className="col">
                            <ul>
                                <li><span>Rozmarýnová silice</span></li>
                                <li><span>Yzopová silice</span></li>
                                <li><span>Tymiánová silice</span></li>
                                <li><span>Borovicová silice</span></li>
                                <li><span>Lopuch větší kořen</span></li>
                            </ul>
                        </div>

                        <div className="col">
                            <ul>
                                <li><span>Cedrová silice</span></li>
                                <li><span>Cypřiš vždyzelený</span></li>
                                <li><span>Hřebíček</span></li>
                                <li><span>Heřmánek pravý</span></li>
                                <li><span>Vrbová kůra</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Effects
