import React from "react"

import SEO from "../components/seo"

import './index.scss'

import Header from "../components/header/header"
import Cover from "../components/cover/cover"
import About from "../components/about/about"
import Parallax from "../components/parallax/parallax";
import Effects from "../components/effects/effects";
import Footer from "../components/footer/footer";
import Lidl from "../components/lidl/lidl";
import Modal from "../components/modal/modal";

const IndexPage = () => (
    <>
        <SEO title="Jezevčí Mast"/>
        <Header/>
        <Cover/>
        <About/>
        <Parallax label="Jezevčí mast" title="Produkt na přírodní bázi,<br /> který lze používat dlouhodobě" image={1}/>
        <Effects/>
        <Parallax label="Jezevčí mast" title="Balzám složený z bylin<br /> sbíraných v lesích" image={2}/>
        <Footer/>
    </>
)

export default IndexPage
